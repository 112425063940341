import {useEffect, useState} from "react";
import {authApi} from "../aaxios";
import useEditMainListState from "../recoil/useEditMainListState";

async function loadDetail(sessionId) {
    const reqUrl = `/session/get_detail?sessionId=${sessionId}`
    const res = await authApi.get(reqUrl)
    return res.data
}

export default function useLoadDetailSession(props) {
    const {session} = props
    const [detail, setDetail] = useState(null)
    const {dispatchRefresh} = useEditMainListState();

    useEffect(() => {
        if (!session) return
        const {session_id} = session
        if (!session_id) return
        loadDetail(session_id).then((data) => {
            if (data && data.result) {
                setDetail(data.result)
            }
        })

    }, [session])

    async function onConfirmSession() {
        if (!session) return
        const {session_id} = session

        let status_confirm = '검토 완료';
        const url = `/session/confirm?sessionId=${session_id}&category=${status_confirm}`
        try {
            const res = await authApi.get(url)
            if (res.status === 200) {
                alert("검토완료 되었습니다.");
                dispatchRefresh()
                return
            }
            alert("검토완료 실패했습니다.");
        } catch (error) {
            alert("시스템 오류 : 관리자 문의");
        }
    }

    async function onRemoveSession() {
        if (!session) return
        const {session_id} = session

        const removeConfirm = window.confirm("게시글을 삭제하면 복구할 수 없습니다. \n 정말 삭제 하시겠습니까?")
        if (!removeConfirm) return
        try {
            const res = await authApi.delete(`session/delete?sessionId=${session_id}`)
            if (res.status === 204) {
                alert("삭제 완료")
                dispatchRefresh()
                return
            }
            alert("삭제 실패")
        } catch (e) {
            alert("삭제 오류 : 관리자 문의");
        }

    }


    return {
        detail,
        onConfirmSession,
        onRemoveSession,
    }
}