import React, {useState, useEffect} from 'react';
import '../App.css';
import {adminApi} from "../aaxios";
import {clearToken, setToken} from "../infra/token/token_storage";


async function clickLogin(idValue, passwordValue) {
    clearToken()
    try {
        const res = await adminApi.post('/token', {
            username: idValue,
            password: passwordValue
        })
        const {refresh} = res.data
        if (refresh) {
            setToken(res.data)
            window.location.href = "/home";
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            alert("아이디 또는 비밀번호가 잘못되었습니다.");
            return
        }
        alert("로그인 중 문제가 발생했습니다. 잠시 후 다시 시도해주세요.");
    }

}

export default function Login() {
    const [idValue, setIdValue] = useState("")
    const [passwordValue, setPasswordValue] = useState("")


    return (
        <>
            <div className="intro-frame">
                <h2>뷰성형외과 관리자 페이지</h2>
                <div className="intro-imgcontainer">
                    <img src="img/viewbeauty_01.svg" alt="Avatar" className="avatar"/>
                </div>

                <div className="container">
                    <label className="input-data"><b>관리자 ID</b></label>
                    <input className="search-input" type="text" name="id" id='inputID' value={idValue}
                           onChange={(event) => {
                               setIdValue(event.target.value)
                           }}/>

                    <label className="input-data"><b>Password</b></label>
                    <input className="search-input" type="password" name="call_id" id='inputPassword'
                           value={passwordValue} onChange={(event) => {
                        setPasswordValue(event.target.value)
                    }}/>
                    <button type="submit" className="search-button"
                            onClick={() => clickLogin(idValue, passwordValue)}>로그인
                    </button>
                </div>
            </div>
        </>
    )
}
