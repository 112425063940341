import axios from 'axios';
import {clearToken, getToken, setToken} from "./infra/token/token_storage";

const adminApi = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_BASE_URL,
});


const camelApi = axios.create({
    baseURL: process.env.REACT_APP_CAMEL_API_BASE_URL,
});


const authApi = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_BASE_URL,
});

authApi.interceptors.request.use(
    (config) => {
        const token = getToken()
        if (token && token.access) {
            config.headers['Authorization'] = `Bearer ${token.access}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

authApi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Access 토큰이 만료된 경우
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const token = getToken()
            if (!token) return
            const {refresh} = token
            // 리프레시 토큰을 통해 새로운 Access 토큰을 요청
            if (refresh) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASE_URL}/token/refresh`, {
                        refresh,
                    });

                    const newAccessToken = response.data['access'];
                    setToken({
                            access: newAccessToken,
                            refresh
                        }
                    )
                    // 원래 요청에 새로운 Access 토큰을 설정하여 다시 요청
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return authApi(originalRequest);
                } catch (error) {
                    // 리프레시 토큰이 유효하지 않으면 로그아웃 또는 다른 처리
                    clearToken()
                    window.location.href = '/';
                    return Promise.reject(error);
                }
            }
        }

        return Promise.reject(error);
    }
);


export {
    adminApi,
    camelApi,
    authApi
};

