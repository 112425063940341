import {authApi} from "../aaxios";
import {useEffect, useState} from "react";
import useEditMainListState from "../recoil/useEditMainListState";

async function getSessionList(props) {
    const {page, status} = props;
    const requestUrl = `/session/get_list?page=${page}&status=${status}`;
    const res = await authApi.get(requestUrl)
    return res.data;
}

function calPaging(page, maxPage) {
    const btnRange = 10; // 보여질 페이지 버튼의 개수
    const currentSet = Math.ceil(page / btnRange); // 현재 버튼이 몇번째 세트인지 나타내는 수
    const startPage = (currentSet - 1) * btnRange + 1; // 현재 보여질 버튼의 첫번째 수
    const endPage = startPage + btnRange - 1; // 현재 보여질 끝 버튼의 수
    const totalSet = Math.ceil(maxPage / btnRange); // 전체 버튼 세트 수

    return {
        page,
        maxPage,
        btnRange,
        currentSet,
        startPage,
        endPage,
        totalSet
    }
}

export default function useLoadSessionList() {
    const [status, setStatus] = useState('all');
    const [paging, setPaging] = useState(calPaging(1, 0));
    const [dataList, setDataList] = useState([])
    const [selectedSession, setSelectedSession] = useState(null);
    const {
        mainListEvent,
        dispatchRefresh,
        dispatchLoaded,
        dispatchLoadFailed,
        isRefresh,
        isLoading
    } = useEditMainListState()

    async function loadList() {
        setSelectedSession(null)

        let data = await getSessionList({
            page: paging.page,
            status: status
        })
        const newMaxPage = data['total_page_cnt']
        let newPage = paging.page

        /*
        * 삭제 하다보면 현재 페이지보다 맥스 페이지 적어지는 현상 발생
        *  이때 재조회한다.
        * */
        if (paging.page > newMaxPage) {
            newPage = newMaxPage
            data = await getSessionList({
                page: newPage,
                status: status
            })
        }
        setPaging(calPaging(newPage, newMaxPage))
        setDataList(data.result)
        dispatchLoaded()
    }


    useEffect(() => {
        if (isRefresh()) {
            loadList().catch(() => dispatchLoadFailed())
        }
        if (isLoading()) {
            loadList().catch(() => dispatchLoadFailed())
        }

    }, [
        mainListEvent
    ])


    function onChangePage(nextPage) {
        setPaging({
            ...paging,
            page: nextPage
        })
        dispatchRefresh()
    }

    function setStatusWithPage(s) {
        setStatus(s)
        setPaging({
            ...paging,
            page: 1
        })
        dispatchRefresh()
    }


    return {
        paging,
        dataList,
        status,
        setStatus: setStatusWithPage,
        selectedSession,
        setSelectedSession,
        onChangePage,
    }
}