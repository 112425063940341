import React, {useEffect, useState} from "react";
import "../../App.css"
import useLoadDetailSession from "../../hooks/useLoadDetailSession";
import useAnswer from "../../hooks/useLoadAnswer";
import LoadingComponent from "../LoadingComponent";

function SessionTab(props) {
    const {
        index, date, time,
        onClose,
        onDelete,
        onConfirm
    } = props

    function handleClose(e) {
        e.stopPropagation()
        onClose && onClose()
    }

    function handleDelete(e) {
        e.stopPropagation()
        onDelete && onDelete()
    }

    function handleConfirm(e) {
        e.stopPropagation()
        onConfirm && onConfirm()
    }

    return (
        <div className='detail_content_tap'>
            <div>
                <p>{index + 1}•{date}•{time}</p>
            </div>
            <button className='button_01'
                    style={{background: '#63226A', color: 'white', cursor: 'pointer'}}
                    onClick={handleConfirm}>검토완료
            </button>
            <button className='button_01'
                    style={{
                        background: '#c30c0c', color: '#FFF', cursor: 'pointer'
                    }}
                    onClick={handleDelete}>삭제
            </button>
            <button className='button_01'
                    style={{cursor: 'pointer'}}
                    onClick={handleClose}>닫기
            </button>
        </div>
    )
}


function UserInfo(props) {
    const {detail} = props
    if (!detail) return null
    const {user_name, user_number} = detail
    return (
        <div className='user-info'>고객명 : {user_name ? user_number : 'null'}
            <br/>연락처
            : {user_number ? user_number : 'null'}</div>
    )
}

function Greeting(props) {
    const {detail} = props
    if (!detail) return null
    const {session_detail} = detail
    if (!session_detail) return null

    return (
        <div id='greeting' className='greeting'>{
            session_detail[0]['first_greet']
        }</div>
    )
}

function RegenAnswer(props) {
    const {answerId, regen, onSaveRegen, onCloseRegen, status} = props

    if (status === 'Loading') {
        return <LoadingComponent/>
    }
    if (status === "Saved") {
        return null
    }

    if (!regen) return null
    if (regen.status !== 200) return null
    const {answer} = regen['result']
    const style = {float: 'left', marginRight: '8px', cursor: 'pointer'};
    return (
        <div className='each_answer' id="regen_${answer_id}">
            <p>{answer}</p>
            <div style={{display: 'inline-block', marginTop: '8px'}}>
                <button className='button_02' style={style} onClick={() => onSaveRegen(answerId)}>답변적용</button>
                <button className='button_02' style={style} onClick={() => onCloseRegen(answerId)}>취소</button>
            </div>
        </div>
    )
}

function EditAnswer(props) {
    const [editingAnswer, setEditingAnswer] = useState(props.answer)
    const {
        answer_id,
        onEdit,
        onClose,
        status
    } = props

    useEffect(() => {
        setEditingAnswer(props.answer)
    }, [props.answer]);


    if (status !== "Editing") {
        return null
    }

    function onChangeTxt(e) {
        const newVal = e.target.value
        setEditingAnswer(newVal)
    }

    return (
        <div className='each_answer'>
              <textarea
                  className='edit_input_frame'
                  onChange={onChangeTxt}
                  defaultValue={props.answer}
              />
            <div style={{display: 'inline-block', marginTop: '8px'}}>
                <button
                    className='button_02'
                    style={{float: 'left', marginRight: '8px'}}
                    onClick={() => onEdit(editingAnswer)}
                >
                    답변완료
                </button>
                <button
                    className='button_02'
                    style={{float: 'left'}}
                    onClick={() => onClose()}
                >
                    취소
                </button>
            </div>
        </div>
    )
}

function Answer(props) {
    let {
        answer_id,
        question,
        // answer_num,
    } = props

    const {
        regenAnswerRequest,
        regenAnswer,
        clearRegen,
        saveRegen,
        regenAnswerStatus,
        answer,
        update_admin_id,
        updateAtDate,
        editStatus,
        onEditing,
        onCloseEdit,
        onEdit,
        like,
        dislike,
        onLike,
        onDislike,
    } = useAnswer(props)

    function getUpdateAtViewString(u) {
        const datePart = u.split('T')[0];
        const timePart = u.split('T')[1].split(':')[0] + ':' + u.split('T')[1].split(':')[1];
        return datePart + ' ' + timePart
    }

    return (
        <div key={answer_id}>
            <div className='each_question' id={`each_question${answer_id}`}>
                <img alt="Me" width="30" height="30" decoding="async" className="Home_usericon___BrVD"
                     src="/img/user.svg" style={{color: 'transparent', float: 'left'}}/>
                <p style={{paddingLeft: 50, margin: '16px 0'}}>{question}</p>
                <button className='button_02' onClick={() => regenAnswerRequest({
                    answer_id
                })}>AI 답변 재생성
                </button>
            </div>

            <RegenAnswer
                regen={regenAnswer}
                answerId={answer_id}
                onSaveRegen={saveRegen}
                onCloseRegen={clearRegen}
                status={regenAnswerStatus}
            />

            <EditAnswer
                answer={answer}
                status={editStatus}
                onClose={onCloseEdit}
                onEdit={onEdit}
            />


            <div className='each_answer' id={`each_answer${answer_id}`}>
                <img alt="AI" width="40" height="40" decoding="async" className="Home_boticon__Xr0Q4"
                     src="/img/view_user.svg" style={{color: 'transparent', float: 'left', paddingRight: 10}}/>
                <p style={{
                    paddingLeft: 50,
                    textAlign: 'left',
                    marginTop: 0,
                    marginBottom: 30,
                    lineHeight: 'calc(1ex / 0.32)'
                }}>{answer}</p>

                <div className='detail_buttonlist'>
                    <button className='button_02' onClick={() => onEditing()}>관리자 답변 수정</button>

                    <img className='like_button' src={like ? 'img/like_activate.png' : 'img/like_deactivate.png'}
                         alt='Like' onClick={() => onLike()}/>
                    <img className='like_button'
                         src={dislike ? 'img/dislike_activate.png' : 'img/dislike_deactivate.png'} alt='Dislike'
                         onClick={() => onDislike()}/>
                </div>

                <div style={{display: 'flow-root'}}>
                    <p style={{float: 'left'}}>작성 : {update_admin_id}</p>
                    <p style={{float: 'right'}}>시간 : {getUpdateAtViewString(updateAtDate)}</p>
                </div>
            </div>
        </div>

    )
}

function AnswerList(props) {
    const {detail} = props
    if (!detail) return null
    const {session_detail} = detail
    if (!session_detail) return null
    const answerDataList = session_detail.slice(1);
    return (
        <>{
            answerDataList.map((answer, key) => {
                return (
                    <Answer {...answer} key={key}/>
                )
            })
        }
        </>
    )
}


export default function SessionEdit(props) {
    const {
        session,
        onClose
    } = props
    const {
        detail,
        onRemoveSession,
        onConfirmSession,
    } = useLoadDetailSession(props)

    if (!session) {
        return null
    }
    return (
        <div id='detail_content_v2' className='detail_content'>
            <SessionTab {...session}
                        onClose={onClose}
                        onDelete={onRemoveSession}
                        onConfirm={onConfirmSession}
            />
            <UserInfo detail={detail}/>
            <Greeting detail={detail}/>
            <AnswerList detail={detail}/>
        </div>
    )
}