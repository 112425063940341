import {authApi} from "../aaxios";
import {useEffect, useState} from "react";

function refineAnswerString(answer) {
    answer = answer.replace(/'/g, ""); // 작은 따옴표를 전역적으로 찾아서 공백으로 바꾸는 정규 표현식
    answer = answer.replace(/"/g, "");
    answer = answer.replace(/[^\w\s?.~ㄱ-ㅎㅏ-ㅣ가-힣]/gi, '');
    return answer
}

function getLocalISOString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}


export default function useLoadAnswer(answerProps) {

    const [answer, setAnswer] = useState(refineAnswerString(answerProps.answer))
    const [regenAnswer, setRegenAnswer] = useState(null);
    const [regenAnswerStatus, setRegenAnswerStatus] = useState("None")
    const [update_admin_id, setUpdateAdminId] = useState(answerProps.update_admin_id ? answerProps.update_admin_id : 'AI 답변')
    const [updateAtDate, setUpdateAtDate] = useState(answerProps.updated_at)
    const [editStatus, setEditStatus] = useState("None")
    const [like, setLike] = useState(answerProps.like)
    const [dislike, setDislike] = useState(answerProps.dislike)

    useEffect(() => {
        // 초기화 로직.
        setAnswer(answerProps.answer)
        setRegenAnswer(null)
        setRegenAnswerStatus("None")
        setUpdateAdminId(answerProps.update_admin_id ? answerProps.update_admin_id : 'AI 답변')
        setUpdateAtDate(answerProps.updated_at)
        setEditStatus("None")
        setLike(answerProps.like)
        setDislike(answerProps.dislike)
    }, [answerProps.answer_id]);

    async function regenAnswerRequest({
                                          answer_id,
                                      }) {
        const reqUrl = `/answer/get_regen?answerId=${answer_id}`
        setRegenAnswerStatus("Loading")
        try {
            const res = await authApi.get(reqUrl)
            setRegenAnswer(res.data)
            return res.data
        } finally {
            setRegenAnswerStatus("Loaded")
        }
    }

    function clearRegen() {
        setRegenAnswer(null)
    }

    async function saveRegen(answerId) {
        if (regenAnswerStatus !== "Loaded") {
            alert("답변이 재생성 되지 않았습니다. 새로 고침 후 재시도");
            return
        }

        const {answer} = regenAnswer['result']
        const answerInfo = {
            answerID: answerId,
            regenAnswer: answer,
            adminId: ''
        }
        setRegenAnswerStatus("Saving")
        try {
            const res = await authApi.post('/answer/save_regen', answerInfo)
            if (res.status === 200) {
                setRegenAnswerStatus("Saved")
                setAnswer(answerInfo.regenAnswer)
                setUpdateAtDate(getLocalISOString())
                // 추가적으로 해줘야함..
                // setUpdateAdminId()
                alert("재생성 답변 DB 적용 완료");
                return
            }
            setRegenAnswerStatus("FailSave")
            alert("재생성 답변 DB 적용 실패");
        } catch (e) {
            setRegenAnswerStatus("FailSave")
            alert("시스템 점검중 : 관리자 문의");
        }
    }

    function onEditing() {
        setEditStatus("Editing")
    }

    function onCloseEdit() {
        setEditStatus("None")
    }

    async function onEdit(newTxt) {
        if (!newTxt || /^\s*$/.test(newTxt)) {
            alert("수정값 제대로 입력 해주세요.")
            return
        }
        const answerInfo = {
            answerID: answerProps.answer_id,
            editedAnswer: newTxt,
            adminId: ''
        };

        try {
            setEditStatus("Editing")
            const res = await authApi.post(`/answer/edit`, answerInfo)
            if (res.status === 200) {
                setEditStatus("Edited")
                setAnswer(answerInfo.editedAnswer)
                setUpdateAtDate(getLocalISOString())
                alert("수정 답변 DB 적용 완료");
                return
            }
            alert("수정 답변 DB 적용 실패");
            setEditStatus("EditFail")
        } catch (e) {
            setEditStatus("EditError")
            alert("시스템 점검중 : 관리자 문의");
        }
    }

    async function requestEvaluate(like) {

        let answerInfo = {
            'answerId': answerProps.answer_id,
            'like': true,
            'dislike': false
        };
        if (!like) {
            answerInfo['like'] = false;
            answerInfo['dislike'] = true;
        }

        try {
            const res = await authApi.post('/answer/evaluate', answerInfo)
            if (res.status === 200) {
                setLike(answerInfo.like)
                setDislike(answerInfo.dislike)
            }
        } catch (e) {
        }
    }

    async function onLike() {
        await requestEvaluate(true)
    }

    async function onDislike() {
        await requestEvaluate(false)
    }

    return {
        regenAnswerRequest,
        regenAnswer,
        clearRegen,
        saveRegen,
        regenAnswerStatus,
        answer,
        update_admin_id,
        updateAtDate,
        editStatus,
        onEditing,
        onCloseEdit,
        onEdit,
        like,
        dislike,
        onLike,
        onDislike
    }
}