import React, {useState, useEffect} from 'react';
import '../App.css';
import LoginRoute from "../container/LoginRoute";
import TableNav from "../Components/main/TableNav";
import TableBody from "../Components/main/TableBody";
import useLoadSessionList from "../hooks/useLoadSessionList";
import SessionEdit from "../Components/main/SessionEdit";
import PageNavigation from "../Components/main/PageNavigation";

const TABLE_HEADER_ITEMS = [
    {
        label: '전체',
        id: 'all',
    },
    {
        label: '미확인',
        id: 'check',
    },
    {
        label: '수정 필요',
        id: 'edit',
    },
    {
        label: '검토 완료',
        id: 'done',
    }
]

export default function MainPage(props) {
    const {
        dataList,
        status,
        setStatus,
        setSelectedSession,
        selectedSession,
        onChangePage,
        paging
    } = useLoadSessionList()


    function onClickTabNav(props) {
        const {id} = props
        setStatus(id)
    }

    function onClickTableRow(item) {
        setSelectedSession(item)
    }

    function onCloseSelectedSession() {
        setSelectedSession(null);
    }

    return (
        <LoginRoute>
            <div className='wrap_home'>
                <div className='wrap_header'>
                    <TableNav
                        status={status}
                        items={TABLE_HEADER_ITEMS}
                        onClick={onClickTabNav}
                    />
                </div>

                <div className='wrap_board' id='wrap_board_2'>
                    <TableBody items={dataList || []} onClick={onClickTableRow}/>
                    <SessionEdit session={selectedSession} onClose={onCloseSelectedSession}/>
                </div>

                <div className='page_number' id='page_number1'>
                    <PageNavigation paging={paging} onChangePage={onChangePage}/>
                </div>
            </div>
        </LoginRoute>
    )
}