import React from "react";
import "../../App.css"

export function TableItem(props) {
    let {
        session_id,
        content,
        date,
        time,
        status,
        user_name,
        user_number,
        onClick,
        index,
    } = props

    function handleClick(e) {
        e.stopPropagation()
        console.log("row 클릭")
        onClick && onClick()
    }

    return (
        <tr key={session_id}
            onClick={handleClick}>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{index + 1}</td>
            <td className='col_width_content' style={{color: 'var(--black, #000)'}}>{content}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{date}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{time}</td>
            <td className='col_width_basic' style={{color: 'var(--red, #DB524C)'}}>{status}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{user_name ? user_name : 'X'}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{user_number ? user_number : 'X'}</td>
        </tr>
    )
}


export default function TableBody(props) {
    const {items, onClick} = props;
    return (
        <table style={{width: '95%'}} className='table_frame' id='TableBody'>
            <thead className='TableBodyThead'>
            <tr>
                <th className='col_width_basic'>글번호</th>
                <th className='col_width_content'>내용</th>
                <th className='col_width_basic'>날짜</th>
                <th className='col_width_basic'>시간</th>
                <th className='col_width_basic'>상태</th>
                <th className='col_width_basic'>고객성함</th>
                <th className='col_width_basic'>연락처</th>
            </tr>
            </thead>
            <tbody id='TableBodyTBody'>{
                items.map((item, key) => {
                    return (
                        <TableItem {...item} index={key}
                                   key={key}
                                   onClick={() => onClick({
                                       ...item,
                                       index: key
                                   })}
                        />
                    )
                })
            }</tbody>
        </table>
    )
}